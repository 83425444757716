import { WebStorage } from "@shared/storage";
import type { GAProduct } from "./voice-analytics-repository";
import EcommerceAnalyticsRepository from "./voice-analytics-repository";
import type {
  AdditionalServices,
  MbbBundle,
  Offering,
  Subscription,
} from "Scripts/Src/Components/ShoppingCart/shop-types";
import { calculateEcommerceValue, extractProductAddons, extractProductCategories } from "./voice-analytics-utils";
import {
  EcommerceEvents,
  EventLabel,
  type GA4Event,
  GA4GoToCheckoutButtonClick,
  GA4HardwareProduct,
  GA4OrderingButtonClick,
  type GA4Product,
  type GA4ProductCategories,
  GAEvent,
  type ProductEvent,
  type ProductPlacementMetadata,
  type ProductsEvent,
} from "./voice-analytics-types";
import { getGA4BundleForSubscriptionAndRouter } from "../mbb/b2c/mbb-b2c-analytics";
import { addIndexToItems, getBusinessUnit } from "@shared/Utils/analytics-utils";
import { pushToDataLayer } from "@shared/Analytics/data/data-layer.facade";
import {
  Mbb_SHOPPING_CART_EVENTS,
  SHOPPING_CART_EVENTS,
} from "../../../../Components/ShoppingCart/Components/shopping-cart-constants";
import { isB2BPage } from "@shared/Utils";
import { mapHardwareCardElementToGA4HardwareProduct } from "./Voice-analytics-map";

export class EcommerceAnalytics {
  _ecommerceRepository: EcommerceAnalyticsRepository;
  _productImpressionObserver: IntersectionObserver;
  _fallbackListName = "Voice list";

  constructor() {
    this._ecommerceRepository = new EcommerceAnalyticsRepository(new WebStorage(localStorage));

    this.initProductPosition();

    this.initProductImpressionObserver();

    this.initHardwareImpressionObserver();

    this.initOrderingButtonClicked();

    this.initGoToCheckoutButtonClicked();

    document.addEventListener(
      EcommerceEvents.TrackProductDetails,
      (e: ProductEvent) => void this.handleTrackProductDetails(e),
    );

    document
      .querySelectorAll("a[data-enable-product-click-tracking]")
      .forEach((el) => el.addEventListener("click", (e: Event) => void this.handleTrackProductClick(e)));

    document
      .querySelectorAll("a[data-enable-hardware-click-tracking]")
      .forEach((el) => el.addEventListener("click", (e: Event) => void this.handleTrackHardwareClick(e)));

    document.addEventListener(EcommerceEvents.TrackAddToCart, (e: ProductsEvent) => void this.handleTrackAddToCart(e));

    document.addEventListener(
      EcommerceEvents.TrackRemoveFromCart,
      (e: ProductsEvent) => void this.handleTrackRemoveFromCart(e),
    );

    // Let listeners know the module is ready to use
    document.dispatchEvent(new CustomEvent(EcommerceEvents.TrackingInstantiated));
  }

  initProductPosition = (): void => {
    const productImpressionContainers = document.querySelectorAll("[data-product-tracking-container]");

    for (const container of productImpressionContainers) {
      const productElements = container.querySelectorAll("[data-product-tracking-id]");
      productElements.forEach((p: HTMLElement, idx) => {
        p.dataset.productTrackingPosition = `${idx + 1}`;
      });
    }
  };

  initProductImpressionObserver = (): void => {
    const productImpressionObserverCallback = async (
      products: IntersectionObserverEntry[],
      observer: IntersectionObserver,
    ): Promise<void> => {
      const productIdPositionPairs: Array<ProductPlacementMetadata> = [];
      await products
        .filter((p) => p.isIntersecting)
        .map((p) => {
          const el = p.target as HTMLElement;
          observer.unobserve(el);
          return {
            alias: el.dataset.productTrackingId,
            position: el.dataset.productTrackingPosition,
            list: el.dataset.productTrackingList,
          };
        })
        .reduce(async (previousPromise, { alias, position, list }) => {
          await previousPromise;
          return this.mapAliasToId({ alias, position, list }).then((i) => {
            productIdPositionPairs.push(i);
          });
        }, Promise.resolve());
      if (!productIdPositionPairs.length) return;
      const productImpressions = productIdPositionPairs.reduce((acc, next: ProductPlacementMetadata) => {
        return {
          ...acc,
          ...next,
        };
      }, {});
      await this.handleProductImpression(productImpressions);
    };

    const productObserver = new IntersectionObserver(
      (elements, observer) => void productImpressionObserverCallback(elements, observer),
      { threshold: [1] },
    );

    const productImpressionContainers = document.querySelectorAll<HTMLElement>("[data-product-tracking-container]");
    for (const container of productImpressionContainers) {
      const productList = container.dataset.productTrackingContainer;
      const productElements = container.querySelectorAll<HTMLElement>("[data-product-tracking-id]");
      productElements.forEach((p, idx) => {
        p.dataset.productTrackingPosition = `${idx + 1}`;
        p.dataset.productTrackingList = productList;
        productObserver.observe(p);
      });
    }
  };

  initHardwareImpressionObserver = (): void => {
    const callback = async (elements: IntersectionObserverEntry[], observer: IntersectionObserver): Promise<void> => {
      const ga4HardwareProducts: GA4HardwareProduct[] = elements
        .filter((element) => element.isIntersecting)
        .map((element) => {
          const el = element.target as HTMLElement;
          observer.unobserve(el);
          return mapHardwareCardElementToGA4HardwareProduct(el);
        })
        .filter((ga4HardwareProduct) => ga4HardwareProduct !== undefined);
      if (!ga4HardwareProducts.length) {
        return;
      }
      await this.handleHardwareImpression(ga4HardwareProducts);
    };

    const hardwareObserver = new IntersectionObserver((elements, observer) => void callback(elements, observer), {
      threshold: 1,
    });

    const containers = document.querySelectorAll<HTMLElement>("[data-product-tracking-container]");
    for (const container of containers) {
      const listName = container.dataset.productTrackingContainer;
      const elements = container.querySelectorAll<HTMLElement>("[data-hardware-tracking-name]");
      elements.forEach((element, idx) => {
        element.dataset.productTrackingIndex = String(idx);
        element.dataset.productTrackingList = listName;
        hardwareObserver.observe(element);
      });
    }
  };

  initOrderingButtonClicked = (): void => {
    const pdpButtons = document.querySelectorAll("ice-addoffering-button[productpage='pdp']");
    for (const pdpButton of pdpButtons) {
      const cta = pdpButton.closest("#sticky-container") ? "bestill_sticky" : "bestill";
      const productName = pdpButton.getAttribute("productname") ?? "";
      pdpButton.addEventListener("click", () => {
        const event: GA4OrderingButtonClick = {
          event: "button_click",
          element_category: "mobilabonnement_bestill",
          element_cta: cta,
          item_name: productName,
          item_business_unit: getBusinessUnit(),
        };
        pushToDataLayer(event);
      });
    }
  };

  initGoToCheckoutButtonClicked = (): void => {
    document.addEventListener(SHOPPING_CART_EVENTS.GO_TO_CHECKOUT, () => {
      const event: GA4GoToCheckoutButtonClick = {
        event: "button_click",
        element_category: "mobilabonnement_bestill",
        element_cta: "til bestilling",
        business_unit: getBusinessUnit(),
      };
      pushToDataLayer(event);
    });

    document.addEventListener(Mbb_SHOPPING_CART_EVENTS.GO_TO_CHECKOUT, () => {
      const event: GA4GoToCheckoutButtonClick = {
        event: "button_click",
        element_category: "mobilt-bredbånd_bestill",
        element_cta: "til bestilling",
        business_unit: getBusinessUnit(),
      };
      pushToDataLayer(event);
    });
  };

  handleProductImpression = async (productImpressions: ProductPlacementMetadata): Promise<void> => {
    const products = await this._ecommerceRepository.getProducts(Object.keys(productImpressions));
    if (!products.length) return;

    if (this.HasMbbProducts(products)) {
      this.SendMbbProductsImpressionToGA(productImpressions, products);
      return;
    }

    /* GA4 implementation */
    const ga4ProductsWithPosition = products
      .map((p, _, allProducts) => {
        const ga4Product = this.mapToGA4Product(p, allProducts);
        return {
          ...ga4Product,
          index: Number.parseInt(productImpressions[p.id].position, 10),
          item_list_name: productImpressions[p.id].list,
        };
      })
      .sort((a, b) => a.index - b.index);
    const listname = ga4ProductsWithPosition[0].item_list_name;
    const ga4ProductDetailEvent = {
      event: "view_item_list",
      ecommerce: {
        item_list_name: listname,
        items: ga4ProductsWithPosition,
      },
    };

    this.trackEcommerceGA4Event(ga4ProductDetailEvent);
  };

  handleHardwareImpression = async (ga4HardwareProducts: GA4HardwareProduct[]): Promise<void> => {
    if (!ga4HardwareProducts.length) {
      return;
    }

    const listName = ga4HardwareProducts[0]?.item_list_name;
    const ga4Event = {
      event: "view_item_list",
      ecommerce: {
        item_list_name: listName,
        items: ga4HardwareProducts,
      },
    };

    this.trackEcommerceGA4Event(ga4Event);
  };

  HasMbbProducts = (products: GAProduct[]) => {
    return products.some((p) => p.isForMbb);
  };
  SendMbbProductsImpressionToGA = (productImpressions: ProductPlacementMetadata, products: GAProduct[]) => {
    /* GA4 implementation for MBB */

    const ga4ProductsWithPosition = products
      .map((p) => {
        const ga4Product = this.mapMbbProductToGA4Product(p);

        return {
          ...ga4Product,
          index: Number.parseInt(productImpressions[p.id].position, 10),
          item_list_name: productImpressions[p.id]?.list,
        };
      })
      .sort((a, b) => a.index - b.index);
    const listname = ga4ProductsWithPosition[0]?.item_list_name;
    const ga4ProductDetailEvent = {
      event: "view_item_list",
      ecommerce: {
        item_list_name: listname,
        items: ga4ProductsWithPosition,
      },
    };

    this.trackEcommerceGA4Event(ga4ProductDetailEvent);
  };
  handleTrackProductDetails = async ({ detail }: ProductEvent): Promise<void> => {
    const products = await this._ecommerceRepository.getProducts([detail.offeringId]);
    if (!products.length) return;

    const productDetailEvent = {
      eventCategory: "ecommerce",
      eventLabel: this.eventLabel(),
      eventAction: GAEvent.ProductDetail,
      event: GAEvent.ProductDetail,
      ecommerce: {
        detail: {
          actionField: {
            list: this._fallbackListName,
          },
          products: products,
        },
      },
    };
    this.trackEcommerceEvent(productDetailEvent);

    /* GA4 implementation */
    const ga4Products: GA4Product[] = products.map((product, _, allProducts) => {
      return this.mapToGA4Product(product, allProducts);
    });
    const ga4ProductDetailEvent = {
      event: "view_item",
      ecommerce: {
        items: addIndexToItems(ga4Products),
      },
    };
    this.trackEcommerceGA4Event(ga4ProductDetailEvent);
  };

  handleTrackProductClick = async (e: Event): Promise<void> => {
    const element = e.currentTarget as HTMLAnchorElement;
    const closesTrackingIdEl = element.closest<HTMLElement>("[data-product-tracking-id]");
    if (closesTrackingIdEl === null) {
      console.warn("Could not find product tracking id when clicking", e.currentTarget);
      return;
    }

    const productTrackingId = closesTrackingIdEl.dataset.productTrackingId;
    const productTrackingPosition = closesTrackingIdEl.dataset.productTrackingPosition;
    const products = await this._ecommerceRepository.getProducts([productTrackingId]);
    if (!products.length) return;

    const list =
      element.closest<HTMLElement>("[data-product-tracking-container]")?.dataset.productTrackingContainer ||
      this._fallbackListName;

    /* GA4 implementation */
    const ga4Products: GA4Product[] = products.map((product, _, allProducts) => {
      return this.mapToGA4Product(product, allProducts);
    });
    const ga4ProductClickEvent = {
      event: "select_item",
      ecommerce: {
        item_list_name: list,
        items: ga4Products.map((product) => {
          return {
            ...product,
            index: Number.parseInt(productTrackingPosition, 10),
          };
        }),
      },
    };
    this.trackEcommerceGA4Event(ga4ProductClickEvent);
  };

  handleTrackHardwareClick = async (e: Event): Promise<void> => {
    const element = e.currentTarget as HTMLAnchorElement;
    const closesTrackingEl = element.closest<HTMLElement>("[data-hardware-tracking-name]");
    if (closesTrackingEl === null) {
      console.warn("Could not find hardware tracking name when clicking", e.currentTarget);
      return;
    }

    const ga4HardwareProduct = mapHardwareCardElementToGA4HardwareProduct(closesTrackingEl);
    if (!ga4HardwareProduct) {
      return;
    }

    const listName = ga4HardwareProduct.item_list_name;
    const ga4Event = {
      event: "select_item",
      ecommerce: {
        item_list_name: listName,
        items: [ga4HardwareProduct],
      },
    };

    this.trackEcommerceGA4Event(ga4Event);
  };

  handleTrackAddToCart = async ({ detail }: ProductsEvent): Promise<void> =>
    this.handleTrackCart(detail.offeringIds, detail.productPage, GAEvent.AddToCart);

  handleTrackRemoveFromCart = async ({ detail }: ProductsEvent): Promise<void> =>
    this.handleTrackCart(detail.offeringIds, detail.productPage, GAEvent.RemoveFromCart);

  private handleTrackCart = async (
    offeringIds: string[],
    productPage: "plp" | "pdp",
    event: GAEvent.AddToCart | GAEvent.RemoveFromCart,
  ): Promise<void> => {
    const products = await this._ecommerceRepository.getProducts(offeringIds);
    if (!products.length) return;
    const productsWithCartProperties = products.map((product) => ({ ...product, quantity: 1 }));
    const cartEvent = {
      eventLabel: this.eventLabel(),
      event,
      ecommerce: {
        currencyCode: "NOK",
        [event === GAEvent.AddToCart ? "add" : "remove"]: { products: productsWithCartProperties },
      },
      eventCategory: "ecommerce",
      eventAction: GAEvent.AddToCart ? "addToCart" : "removeFromCart",
    };
    this.trackEcommerceEvent(cartEvent);

    /* GA4 implementation */

    const mappedGACartPropsToGA4CartProps: GA4Product[] = products.map((product, productIndex) => {
      const productCategories = extractProductCategories(product.category);
      const productAddons = extractProductAddons(products, productCategories);

      const GA4EventData: GA4Product = {
        item_id: product.id,
        item_name: product.name,
        affiliation: "ice.no",
        item_brand: "Ice",
        item_variant: product.variant,
        item_binding: product.dimension18,
        quantity: 1,
        index: productIndex,
        price: product.price,
        item_business_unit: product.dimension5,
        item_bundle: product.dimension10,
        add_to_cart_location: productPage,
        ...productCategories,
      };

      if (productAddons) {
        GA4EventData.item_addons = productAddons;
      }

      return GA4EventData;
    });

    const cartGA4Event: GA4Event = {
      event: event === GAEvent.AddToCart ? "add_to_cart" : "remove_from_cart",
      ecommerce: {
        currency: "NOK",
        value: calculateEcommerceValue(mappedGACartPropsToGA4CartProps.map((event) => event.price)),
        items: mappedGACartPropsToGA4CartProps,
      },
    };

    this.trackEcommerceGA4Event(cartGA4Event);
  };

  private mapAliasToId = async ({
    alias,
    position,
    list,
  }: {
    alias: string;
    position: string;
    list: string;
  }): Promise<ProductPlacementMetadata> => {
    const id = await this._ecommerceRepository.getProductIdFromAlias(alias);
    return { [id]: { position, list } };
  };

  private eventLabel = (): EventLabel => (isB2BPage() ? EventLabel.VoiceB2B : EventLabel.VoiceB2C);

  private trackEcommerceEvent = (gtmEcommerceEvent: unknown): void => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(gtmEcommerceEvent);
  };

  /* GA4 implementation  */
  private trackEcommerceGA4Event = (gtmEcommerceGA4Event: unknown): void => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(gtmEcommerceGA4Event);
  };

  private mapToGA4Product = (product: GAProduct, allProducts: GAProduct[]) => {
    const productCategories = extractProductCategories(product.category);
    const item_addons = extractProductAddons(allProducts, productCategories);

    const GA4EventData: GA4Product = {
      item_id: product.id,
      item_name: product.name,
      item_brand: "Ice",
      item_variant: product.variant,
      item_binding: product.dimension18,
      item_business_unit: product.dimension5,
      item_bundle: product.dimension10,
      ...(item_addons ? { item_addons } : {}),
      ...productCategories,
      affiliation: "ice.no",
      quantity: 1,
      price: product.price,
    };

    return GA4EventData;
  };

  private mapMbbProductToGA4Product = (product: GAProduct) => {
    const productCategories = extractProductCategories("Service/MBB/data");

    const GA4EventData: GA4Product = {
      item_id: product.id,
      item_name: `${product.name}`,
      item_brand: "Ice",
      item_variant: `MBB ${product.variant}`,
      item_business_unit: product.dimension5,
      item_bundle: "Mobilt Bredbånd",
      ...productCategories,
      affiliation: "ice.no",
      quantity: 1,
      price: product.price,
    };

    return GA4EventData;
  };
}

const _ecommerceRepository = new EcommerceAnalyticsRepository(new WebStorage(localStorage));

export const createViewCartSubscription = (
  product: GAProduct,
  offering: Subscription,
  productCategories: GA4ProductCategories,
) => {
  return {
    item_brand: "Ice",
    affiliation: "ice.no",
    price: product?.price,
    item_id: offering?.planId.replace(/\D/g, ""),
    item_name: `Mobil ${offering?.name}`,
    item_variant: product?.variant,
    item_business_unit: product?.dimension5,
    item_bundle: product?.dimension10,
    item_binding: product?.dimension18,
    item_addons: offering?.additionalServices
      .filter((service) => service.selected)
      .map((service) => service.title)
      .join(", "),
    ...productCategories,
    item_product_type: "SIM",
    quantity: 1,
  };
};

export const createCartServiceItemObject = (service: AdditionalServices, serviceCategory: string) => {
  return {
    item_id: service.serviceId,
    item_name: service.title,
    affiliation: "ice.no",
    item_brand: "ice",
    price: service.price,
    item_variant: service.title,
    item_business_unit: getBusinessUnit(),
    item_bundle: "Addon",
    item_product_type: "Service",
    item_binding: "nei",
    quantity: 1,
    ...extractProductCategories(serviceCategory),
  };
};

export const getViewCartServiceItems = async (service: AdditionalServices[]) => {
  return await Promise.all(
    service
      .filter((service) => service.selected)
      .map(async (service) => {
        const [serviceCategories] = await _ecommerceRepository.getProducts([service.serviceId]);

        return createCartServiceItemObject(service, serviceCategories.category);
      })
      .flat(),
  );
};

const getViewCartEventItems = async (offeringsList: Subscription[]) => {
  return (
    await Promise.all(
      offeringsList.map(async (offering) => {
        const [product] = await _ecommerceRepository.getProducts([offering.planId]);
        const subscription = createViewCartSubscription(product, offering, extractProductCategories(product.category));
        const serviceItems = await getViewCartServiceItems(offering.additionalServices);

        return [subscription, ...serviceItems];
      }),
    )
  ).flat();
};

const getCartData = async (offerings: Offering[], isMBB: boolean, isBusiness: boolean) => {
  const items = isMBB
    ? offerings.filter((o) => (o as MbbBundle).isbusiness === isBusiness).flatMap(getGA4BundleForSubscriptionAndRouter)
    : await getViewCartEventItems(offerings as Subscription[]);

  return {
    value: calculateEcommerceValue(items.map((x) => x.price)),
    items: addIndexToItems(items),
  };
};

export const sendGA4ViewCartData = async (offerings: Offering[], isMbb: boolean, isBusiness: boolean) => {
  const cartData = await getCartData(offerings, isMbb, isBusiness);

  const viewCartEvent = {
    event: GAEvent.ViewCart,
    ecommerce: {
      currency: "NOK",
      ...cartData,
    },
  };

  window.dataLayer.push(viewCartEvent);
};

export const bootstrapEcommerceAnalytics = (): void => {
  new EcommerceAnalytics();
};
