import { capitalizeFirstLetter } from "@shared/Utils";
import { EquipmentType, GA4HardwareProduct, HARDWARE_TYPE_VALUES, HardwareType } from "./voice-analytics-types";

export function isHardwareType(value: string): value is HardwareType {
  return HARDWARE_TYPE_VALUES.some((hw) => hw === value);
}

export function toHardwareType(value: string, defaultValue: HardwareType = "Mobiltelefoner") {
  if (value === "Pentbrukt") {
    return "Mobiltelefoner";
  }
  return isHardwareType(value) ? value : defaultValue;
}

export const toEquipmentType = (value: HardwareType): EquipmentType => {
  switch (value) {
    case "Mobiltelefoner":
    case "Pentbrukt":
      return "Terminal";
    default:
      return "Accessories";
  }
};

export const mapHardwareCardElementToGA4HardwareProduct = (element: HTMLElement): GA4HardwareProduct | undefined => {
  const name = element.dataset.hardwareTrackingName;
  const brand = element.dataset.hardwareTrackingBrand;
  const price = +(element.dataset.hardwareTrackingPrice ?? NaN);
  const index = +(element.dataset.productTrackingIndex ?? NaN);

  const categorySlug = element.dataset.hardwareTrackingSlug;
  const categoryCondition = element.dataset.hardwareTrackingCondition;

  const list = element.dataset.productTrackingList;
  const isInvalid = !name || !brand || isNaN(price) || isNaN(index) || !list;

  if (isInvalid) {
    console.warn(`Invalid data for hardware element:`, element);
    return;
  }

  const hardwareType = toHardwareType(capitalizeFirstLetter(categorySlug));
  const equipmentTypes = toEquipmentType(hardwareType);
  const newOrUsed = categoryCondition?.toLocaleLowerCase() === "new" ? "Ny" : "Brukt";
  return {
    item_name: name,
    affiliation: "nettbutikk.ice.no",
    index: index,
    item_brand: brand,
    item_category: "Hardware",
    item_category2: equipmentTypes,
    item_category3: hardwareType,
    item_category4: newOrUsed,
    item_list_name: list,
    item_business_unit: "B2C",
    item_product_type: `${equipmentTypes} ${newOrUsed}`,
    price: price,
    quantity: 1,
  };
};
