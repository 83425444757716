import type { CustomerLoginSettings } from "./customer-login-interfaces";

export const getCustomerLoginSettings = async (apiUrl: string, currentUrl: string): Promise<CustomerLoginSettings> => {
  return fetch(`${apiUrl}/api/customerlogin/LoginSettings?currentUrl=${currentUrl}`)
    .then((res) => {
      return res.json();
    })
    .then((data: CustomerLoginSettings) => {
      return data;
    });
};

export const checkifCompanyAdmin = async (token: string | undefined): Promise<boolean> => {
  if (!token) {
    return Promise.resolve(false);
  }

  return fetch(`/api/customer/checkifcompanyadmin`, {
    method: "get",
    cache: "no-cache",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then((data: boolean) => {
      return data;
    });
};
