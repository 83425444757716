import { ApplicationInsightsService } from "@shared/application-insights-service";
import { UserManager, UserManagerSettings } from "oidc-client-ts";

export const LoginStates = {
  notSignedIn: "notSignedIn",
  SignedIn: "SignedIn",
  SignedInRedirect: "SignedInRedirect",
  SignedInSilent: "SignedInSilent",
  SignedOutSilent: "SignedOutSilent",
} as const;

export type LoginStateValues = keyof typeof LoginStates;

export interface AuthState {
  user: CustomerUser | null;
  token: string;
  loginState: LoginStateValues;
}

export interface IceAuthServiceParams {
  userManager: UserManager;
  authStateResult: (authState: AuthState) => void;
  applicationInsightsService: ApplicationInsightsService;
}

export interface CustomerUser {
  firstName?: string;
  lastName?: string;
  initials: string;
  fullName?: string;
  isCompanyAdmin: boolean;
  userId: string;
}

export interface MenuItemsModel {
  Href: string;
  Title: string;
  IsCurrent: boolean;
}

export interface CustomerLoginSettings {
  userManager: UserManager;
  userManagerSettings: UserManagerSettings;
  minSideProfil: string;
  minSideBedriftsProfil: string;
  minSideBaseUrl: string;
  applicationInsightsService: ApplicationInsightsService;
}

export interface Customer {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthdate?: Date;
  isCompanyAdmin: boolean;
}

export enum MenuViewState {
  hidden = 0,
  closed = 1,
  open = 2,
}
